body {
  margin: 0%;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}
.home {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  align-items: center;
}

.create {
  display: flex;
  background-color: #ebf5ff;
  height: 200px;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.answer {
  display: flex;
  background-color: #ebf5ff;
  height: 200px;
  width: 300px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-direction: column;
}
input {
  border: none;
  font-family: inherit;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 50px; */
}

button {
  display: inline-block;
  border: none;
  font: inherit;
  padding: 0.8rem 2rem;
  border-radius: 6px;
  background-color: #4485ff;
  color: white;
  cursor: pointer;
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 30px;
  color: var(--color-primary);
  background-color: white;
}

.modal h3 {
  margin: 0;
  font-weight: normal;
  color: #4485ff;
}

.modal .container {
  display: flex;
  margin-top: 40px;
}

.modal button.secondary {
  margin-left: auto;
  background-color: #ebf5ff;
  color: #4485ff;
  margin-right: 20px;
}

.videos {
  display: flex;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.local {
  position: absolute;
  border-radius: 15px;
  width: 120px;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
.remote {
  /* position: fixed; */
  width: auto !important;
  height: 95vh !important;
  margin: 0;
  border-radius: 15px;
}

.videos .buttonsContainer {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
}

.videos .buttonsContainer .button {
  border: 2px solid transparent;
  padding: 24px;
  border-radius: 50%;
  font-size: 0;
  transition-property: border-color, box-shadow;
  transition-duration: 500ms;
  transition-timing-function: ease;
}

.videos .buttonsContainer .button:hover {
  box-shadow: 0px 5px 10px #0000001a;
  border-color: transparent;
}

.videos .buttonsContainer .hangup {
  margin-right: 50px;
  background-color: #ff694f;
  color: #ffffff;
}

.videos .buttonsContainer .more {
  background-color: white;
  border-color: #dddddd;
  cursor: pointer;
}

.videos .buttonsContainer .more .popover {
  visibility: hidden;
  position: absolute;
  /* bottom: 100%;
  left: 100%; */
  /* padding: 20px 0px; */
  font-size: 1rem;
  left: 10%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #0000001a;
  bottom: 100px;
}

.videos .buttonsContainer .more:focus-within .popover {
  visibility: visible;
}

.videos .buttonsContainer .more .popover button {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: white;
  color: black;
}

.videos .buttonsContainer .more .popover button svg {
  margin-right: 20px;
}

@media (max-width: 650px) {
  .home {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .modal h3 {
    font-size: 1rem;
  }

  .remote {
    /* position: fixed; */
    width: 100vw !important;
    height: auto !important;
    margin: 0;
    border-radius: 15px;
  }

  .videos {
    width: 100vw;
    height: 100vh;
  }
}
